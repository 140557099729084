<script setup lang="ts">
import { useAdminExplorerStore } from "~/stores/admin/explorer";

const adminExplorerStore = useAdminExplorerStore();
</script>

<template>
  <div>
    <template v-if="adminExplorerStore.getFileList.length > 0">
      <div
        class="text-xl font-medium text-(--ui-text-highlighted) pb-3 px-4 sm:px-6"
      >
        Файлы
      </div>

      <template v-if="adminExplorerStore.getImages.length > 0">
        <div class="text-md pb-3 px-4 sm:px-6">Картинки</div>
        <ul role="list" class="divide-y divide-(--ui-border)">
          <ExplorerFileItem
            v-for="file in adminExplorerStore.getImages"
            :key="file.id"
            :file="file"
          />
        </ul>
      </template>

      <template v-if="adminExplorerStore.getNotImages.length > 0">
        <div class="text-md pb-3 px-4 sm:px-6 mt-6">Другое</div>
        <ul role="list" class="divide-y divide-(--ui-border)">
          <ExplorerFileItem
            v-for="file in adminExplorerStore.getNotImages"
            :key="file.id"
            :file="file"
          />
        </ul>
      </template>
    </template>
  </div>
</template>

<style scoped></style>
