<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";
import { useAdminExplorerStore } from "~/stores/admin/explorer";
import { createErrorToast } from "~/utils/notification/error";

const adminExplorerStore = useAdminExplorerStore();
const fileRef = ref<HTMLInputElement>();

async function onFileChange(e: Event) {
  const input = e.target as HTMLInputElement;

  if (!input.files?.length) {
    return;
  }

  if (input.files.length <= 0) {
    createErrorToast("Не выбран файл");
    return;
  }

  const file = input.files[0];

  if (!file) {
    createErrorToast("Не выбран файл по индексу 0");
    return;
  }

  adminExplorerStore.uploadFile(file);
}

function onFileClick() {
  fileRef.value?.click();
}

function createFolder() {
  adminExplorerStore.setIsCreatingFolder(true);
}

async function back() {
  adminExplorerStore.back();
}
</script>

<template>
  <div
    class="border-b border-(--ui-border) pb-5 px-4 sm:px-6 flex gap-5 justify-between"
  >
    <div v-if="adminExplorerStore.getFolderHistoryIsNotEmpty">
      <UButton
        :icon="IconName.ChevronLeft"
        variant="subtle"
        color="neutral"
        size="xl"
        block
        @click="back"
      />
    </div>
    <div v-else />
    <div class="flex gap-3 justify-end">
      <div>
        <UButton
          :icon="IconName.FolderPlus"
          size="xl"
          variant="subtle"
          color="neutral"
          :loading="adminExplorerStore.getIsLoadingCreateFolder"
          block
          @click="createFolder"
        />
      </div>

      <UFormField name="file">
        <UButton
          :icon="IconName.DocumentArrowUp"
          variant="subtle"
          color="neutral"
          size="xl"
          :loading="adminExplorerStore.getIsLoadingUploadFile"
          block
          @click="onFileClick"
        />

        <input
          ref="fileRef"
          type="file"
          class="hidden"
          accept=".png, .jpg, .jpeg, .txt, .pdf, .doc, .docx, .xls, .xlsx"
          @change="onFileChange"
        />
      </UFormField>
    </div>
  </div>
</template>

<style scoped></style>
