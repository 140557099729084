<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { IconName } from "~/utils/enum/icon_name";
import { useResolveRoute } from "~/composables/route";
import { PublicAsset } from "~/utils/enum/public_asset";

// TODO на мобилке после закрытия сайдбара, стрелочка вниз улетает вправо из-за open == true
const props = defineProps<{
  collapsed?: boolean;
  open?: boolean;
}>();

const nuxtApp = useNuxtApp();

const scopes = [
  {
    label: "Магазин",
    icon: IconName.BuildingStorefront,
    onSelect: () => {
      useResolveRoute(
        {
          name: PageName.Home,
        },
        nuxtApp,
        {
          target: "_blank",
        },
      );
    },
  },
];
</script>

<template>
  <UDropdownMenu
    :items="[scopes]"
    :content="{ align: 'center', collisionPadding: 12 }"
    :ui="{
      content: props.collapsed
        ? 'w-40'
        : 'w-(--reka-dropdown-menu-trigger-width)',
    }"
  >
    <UButton
      v-bind="{
        trailingIcon: collapsed ? undefined : IconName.ChevronDown,
      }"
      :label="collapsed ? undefined : 'Админ панель'"
      color="neutral"
      variant="ghost"
      :square="collapsed"
      class="data-[state=open]:bg-(--ui-bg-elevated)"
      :class="[{ 'w-fit': open }]"
      block
    >
      <template #leading>
        <Image :src="PublicAsset.AlvimaFavicon" class="w-6" />
      </template>
    </UButton>
  </UDropdownMenu>
</template>
