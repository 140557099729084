<script setup lang="ts">
import type { Folder } from "~/domain/entity";
import { IconName } from "~/utils/enum/icon_name";
import { useAdminExplorerStore } from "~/stores/admin/explorer";

const props = defineProps<{
  folder: Folder;
}>();

const adminExplorerStore = useAdminExplorerStore();

const items = [
  [
    {
      label: "Удалить",
      icon: IconName.Trash,
      color: "error",
      onSelect: async () => {
        adminExplorerStore.deleteFolder(props.folder.id);
      },
    },
  ],
];

async function openFolder() {
  adminExplorerStore.loadFolderItemList(props.folder);
}
</script>

<template>
  <li
    class="flex items-center gap-3 py-3 px-4 sm:px-6 cursor-pointer hover:bg-(--ui-bg-elevated) select-none"
    @click="openFolder"
  >
    <UIcon :name="IconName.Folder" size="20" />

    <div class="w-full flex items-center justify-between">
      <div class="flex items-center gap-3">
        <div class="text-sm text-(--ui-text-highlighted)">
          {{ props.folder.title }}
        </div>
      </div>

      <UDropdownMenu :items="items">
        <template #default="{ open }">
          <UButton
            variant="ghost"
            :class="[open && 'bg-(--ui-bg-muted)']"
            @click.stop
          >
            <template #trailing>
              <UIcon :name="IconName.EllipsisVertical" size="30" />
            </template>
          </UButton>
        </template>
      </UDropdownMenu>
    </div>
  </li>
</template>

<style scoped></style>
