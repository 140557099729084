<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { IconName } from "~/utils/enum/icon_name";
import type { RouteLocationNormalized } from "#vue-router";

const router = useRouter();
const route = useRoute();

const siteSettings = useSiteSettingsStore();
const open = ref(false);

const safePageLeave = ref<{
  showConfirmModal: boolean;
  to: RouteLocationNormalized | undefined;
}>({
  showConfirmModal: false,
  to: undefined,
});

const mainLinks = [
  {
    id: "home",
    label: "Главная",
    icon: IconName.Home,
    to: {
      name: PageName.AdminHome,
    },
    tooltip: {
      text: "Краткая сводка",
    },
  },
  {
    id: "category",
    label: "Категории",
    icon: IconName.CategoryList,
    to: {
      name: PageName.AdminCategoryList,
    },
    tooltip: {
      text: "Управление категориями",
    },
  },
  {
    id: "product",
    label: "Товары",
    icon: IconName.ProductList,
    to: {
      name: PageName.AdminProductList,
    },
    tooltip: {
      text: "Управление товарами",
    },
  },
  {
    id: "order",
    label: "Заявки",
    icon: IconName.OrderList,
    to: {
      name: PageName.AdminOrderList,
    },
    tooltip: {
      text: "Управление заявками",
    },
  },
  {
    id: "integrator",
    label: "Запросы",
    icon: IconName.Star,
    to: {
      name: PageName.AdminIntegratorRequestList,
    },
    tooltip: {
      text: "Список интеграторских запросов",
    },
  },
  {
    id: "user",
    label: "Пользователи",
    icon: IconName.Users,
    to: {
      name: PageName.AdminUserList,
    },
    tooltip: {
      text: "Список пользователей",
    },
  },
  {
    id: "customerGroup",
    label: "Группы",
    icon: IconName.UserGroup,
    to: {
      name: PageName.AdminCustomerGroupList,
    },
    tooltip: {
      text: "Список групп пользователей",
    },
  },
];

const footerLinks = [
  {
    id: "site-settings",
    label: "Настройки сайта",
    icon: IconName.SiteSettings,
    to: {
      name: PageName.AdminSiteSettings,
    },
    tooltip: {
      text: "Управление настройками сайта",
    },
  },
];

const createItemLinks = [
  {
    label: "Создать категорию",
    icon: IconName.CategoryList,
    onSelect: () => {
      router.push({
        name: PageName.AdminCategoryCreate,
      });
    },
  },
  {
    label: "Создать товар",
    icon: IconName.ProductList,
    onSelect: () => {
      router.push({
        name: PageName.AdminProductCreate,
      });
    },
  },
  {
    label: "Создать юзера",
    icon: IconName.UserPlus,
    onSelect: () => {
      router.push({
        name: PageName.AdminUserCreate,
      });
    },
  },
  {
    label: "Создать группу",
    icon: IconName.UserGroup,
    onSelect: () => {
      router.push({
        name: PageName.AdminCustomerGroupCreate,
      });
    },
  },
];

const getPageTitle = computed((): string => {
  switch (route.name) {
    case PageName.AdminHome:
      return "Главная";

    case PageName.AdminCategoryList:
      return "Категории";

    case PageName.AdminCategoryCreate:
      return "Создание категории";

    case PageName.AdminCategoryUpdate:
      return "Обновление категории";

    case PageName.AdminCategoryUpdateProductList:
      return "Обновление категории - Порядок товаров";

    case PageName.AdminProductList:
      return "Товары";

    case PageName.AdminOrderList:
      return "Заявки";

    case PageName.AdminOrderId:
      return "Информация о заявке";

    case PageName.AdminUserList:
      return "Список пользователей";

    case PageName.AdminUserCreate:
      return "Создание пользователя";

    case PageName.AdminUserCreateRelations:
      return "Создание пользователя - Связи";

    case PageName.AdminUserUpdate:
      return "Обновление пользователя";

    case PageName.AdminUserUpdateRelations:
      return "Обновление пользователя - Связи";

    case PageName.AdminCustomerGroupList:
      return "Группы пользователей";

    case PageName.AdminSiteSettings:
      return "Настройки сайта";

    case PageName.AdminProductCreate:
      return "Создание товара";

    case PageName.AdminProductCreateMultimedia:
      return "Создание товара - Мультимедиа";

    case PageName.AdminProductCreateRelations:
      return "Создание товара - Связи";

    case PageName.AdminProductCreateCustomerGroupPrice:
      return "Создание товара - Цены";

    case PageName.AdminProductUpdate:
      return "Обновление товара";

    case PageName.AdminProductUpdateMultimedia:
      return "Обновление товара - Мультимедиа";

    case PageName.AdminProductUpdateRelations:
      return "Обновление товара - Связи";

    case PageName.AdminProductUpdateCustomerGroupPrice:
      return "Обновление товара - Цены";

    case PageName.AdminCustomerGroupCreate:
      return "Создание группы пользователей";

    case PageName.AdminCustomerGroupUpdate:
      return "Обновление группы пользователей";

    case PageName.AdminIntegratorRequestList:
      return "Интеграторские запросы";

    default:
      return "???";
  }
});

function onConfirmLeavePage() {
  navigateTo(safePageLeave.value.to);
}

function onRejectLeavePage() {
  safePageLeave.value.showConfirmModal = false;
  safePageLeave.value.to = undefined;
}

router.beforeEach((to, from, next) => {
  open.value = false;

  if (!siteSettings.getAdminIsSafePageLeave) {
    return next();
  }

  if (safePageLeave.value.to) {
    safePageLeave.value.showConfirmModal = false;
    safePageLeave.value.to = undefined;

    return next();
  }

  const fromContainsUpdate = from.path?.toString().indexOf("update") >= 0;
  const fromContainsCreate = from.path?.toString().indexOf("create") >= 0;

  const toContainsUpdate = to.path?.toString().indexOf("update") >= 0;
  const toContainsCreate = to.path?.toString().indexOf("create") >= 0;

  if (
    (fromContainsUpdate && !toContainsUpdate) ||
    (fromContainsCreate && !toContainsCreate)
  ) {
    safePageLeave.value.to = to;
    safePageLeave.value.showConfirmModal = true;

    return next(from);
  }

  return next();
});
</script>

<template>
  <UDashboardGroup>
    <ClientOnly>
      <Explorer />

      <UDashboardSidebar
        v-model:open="open"
        collapsible
        resizable
        class="bg-(--ui-bg)"
        :ui="{ footer: 'lg:border-t lg:border-(--ui-border)' }"
        :menu="{ title: 'Навигация', description: 'Панель навигации' }"
      >
        <template #header="{ collapsed }">
          <AdminScopeDropdown :collapsed="collapsed" :open="open" />
        </template>

        <template #default="{ collapsed }">
          <UNavigationMenu
            :collapsed="collapsed"
            orientation="vertical"
            :items="mainLinks"
          />

          <UNavigationMenu
            :collapsed="collapsed"
            :items="footerLinks"
            orientation="vertical"
            class="mt-auto"
          />
        </template>
      </UDashboardSidebar>

      <UDashboardPanel :id="getPageTitle">
        <template #header>
          <UDashboardNavbar :title="getPageTitle" :ui="{ right: 'gap-3' }">
            <template #leading>
              <UDashboardSidebarCollapse />
            </template>

            <template #right>
              <UDropdownMenu
                :items="[createItemLinks]"
                :popper="{ strategy: 'absolute' }"
              >
                <UButton
                  :icon="IconName.Plus"
                  size="sm"
                  label="Создать"
                  class="ml-1.5 g-primary-3d"
                />
              </UDropdownMenu>
            </template>
          </UDashboardNavbar>
        </template>

        <template #body>
          <slot />
        </template>
      </UDashboardPanel>

      <ModalConfirm
        v-model="safePageLeave.showConfirmModal"
        prevent-close
        description="Изменения были сохранены?"
        confirm-button-label="Изменения сохранены"
        @confirm="onConfirmLeavePage"
        @reject="onRejectLeavePage"
      />
    </ClientOnly>
  </UDashboardGroup>
</template>

<style scoped></style>
