<script setup lang="ts">
import { useAdminExplorerStore } from "~/stores/admin/explorer";
import FolderList from "~/components/explorer/FolderList.vue";
import FileList from "~/components/explorer/FileList.vue";

const adminExplorerStore = useAdminExplorerStore();

watch(
  () => adminExplorerStore.getIsActive,
  async (value: boolean) => {
    if (value) {
      adminExplorerStore.loadFolderItemList();
    }
  },
);
</script>

<template>
  <Modal
    v-model="adminExplorerStore.isActive"
    title="Проводник"
    :description="adminExplorerStore.getFolderHistoryBreadcrumbs"
    fullscreen
    hide-footer
  >
    <ExplorerCreatingFolder />

    <div class="pb-[64px]">
      <LoaderCenter v-if="adminExplorerStore.getIsLoadingFolderItemList" />

      <template v-else>
        <ExplorerToolbarTop />

        <div class="py-5">
          <div
            v-if="adminExplorerStore.getIsEmptyItemList"
            class="text-xl text-(--ui-text-muted) text-center py-12"
          >
            ¯\_(ツ)_/¯
          </div>
          <template v-else>
            <FolderList />

            <USeparator
              v-if="adminExplorerStore.getIsBothListNotEmpty"
              class="py-8"
            />

            <FileList />
          </template>
        </div>
      </template>
    </div>
  </Modal>
</template>

<style scoped></style>
