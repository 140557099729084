<script setup lang="ts">
import type { File as FileEntity } from "~/domain/entity";
import { IconName } from "~/utils/enum/icon_name";
import { useAdminExplorerStore } from "~/stores/admin/explorer";
import { getS3Path, previewS3File } from "~/utils/s3";

const props = defineProps<{
  file: FileEntity;
}>();

const adminExplorerStore = useAdminExplorerStore();

const items = [
  [
    {
      label: "Удалить",
      icon: IconName.Trash,
      color: "error",
      onSelect: async () => {
        adminExplorerStore.deleteFile(props.file.id);
      },
    },
  ],
];

function selectFile() {
  adminExplorerStore.selectFile(props.file);
  adminExplorerStore.setIsActive(false);
  adminExplorerStore.setIsCreatingFolder(false);
}

function previewFile() {
  const { file } = props;
  previewS3File(file);
}

const getIsExcluded = computed((): boolean => {
  return Boolean(
    adminExplorerStore.getFilter?.excludeFileIdList?.includes(props.file.id),
  );
});
</script>

<template>
  <li
    v-if="props.file.showInExplorer"
    class="flex items-center gap-3 py-3 px-4 sm:px-6 cursor-pointer hover:bg-(--ui-bg-elevated) select-none"
    :class="{
      'hidden-item':
        (adminExplorerStore.getFilter?.onlyImage && !props.file.isImage) ||
        getIsExcluded,
    }"
    @click="selectFile"
  >
    <div v-if="props.file.isImage" class="cover w-12" @click.stop="previewFile">
      <Image
        :src="getS3Path(props.file)"
        :alt="props.file.title"
        loading="lazy"
      />
    </div>
    <div v-else class="w-8 h-8">
      <UIcon
        class="w-full h-full object-cover"
        :name="IconName.DocumentSolid"
      />
    </div>

    <div class="w-full flex items-center justify-between">
      <div class="flex flex-col gap-1">
        <div class="font-medium text-(--ui-text-highlighted)">
          {{ props.file.title }}.{{ props.file.extension }}
        </div>

        <!--        <div class="text-sm text-gray-500">-->
        <!--          {{ Number(props.file.size / 1024).toFixed(0) }} КБ-->
        <!--        </div>-->
      </div>

      <UDropdownMenu :items="items">
        <template #default="{ open }">
          <UButton
            variant="ghost"
            :class="[open && 'bg-(--ui-bg-muted)']"
            @click.stop
          >
            <template #trailing>
              <UIcon :name="IconName.EllipsisVertical" size="30" />
            </template>
          </UButton>
        </template>
      </UDropdownMenu>
    </div>
  </li>
</template>

<style scoped>
.hidden-item {
  pointer-events: none;
  opacity: 0.3;
}
</style>
