<script setup lang="ts">
import { useAdminExplorerStore } from "~/stores/admin/explorer";
import { SpecialId } from "~/utils/enum/special_id";

const adminExplorerStore = useAdminExplorerStore();
</script>

<template>
  <div>
    <template v-if="adminExplorerStore.getFolderList.length > 0">
      <div
        class="text-xl font-medium text-(--ui-text-highlighted) pb-5 px-4 sm:px-6"
      >
        Папки
      </div>
      <ul role="list" class="divide-y divide-(--ui-border)">
        <template
          v-for="folder in adminExplorerStore.getFolderList"
          :key="folder.id"
        >
          <ExplorerFolderItem
            v-if="
              folder.id !== SpecialId.AutoUploadedFilesFolderId &&
              folder.id !== SpecialId.UserRequisitesFolderId
            "
            :folder="folder"
          />
        </template>
      </ul>
    </template>
  </div>
</template>

<style scoped></style>
