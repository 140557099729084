<script setup lang="ts">
import { useAdminExplorerStore } from "~/stores/admin/explorer";
import type { FormError, FormSubmitEvent } from "#ui/types";
import type { Folder } from "~/domain/dto";

const adminExplorerStore = useAdminExplorerStore();

function getDefaultFormState() {
  return {
    title: "",
  };
}

let formState = reactive(getDefaultFormState());

function onCloseCreatingFolder() {
  adminExplorerStore.setIsCreatingFolder(false);
}

function validate(state: Partial<Folder>): FormError[] {
  const errors = [];

  if (!state.title) {
    errors.push({ name: "title", message: "Название обязательно" });
  }

  return errors;
}

async function onSubmit(event: FormSubmitEvent<Folder>) {
  adminExplorerStore.createFolder(event.data.title);
  formState = reactive(getDefaultFormState());
}
</script>

<template>
  <UModal
    v-model:open="adminExplorerStore.is.creatingFolder"
    title="Новая папка"
    description="Создание новой папки"
    @close="onCloseCreatingFolder"
  >
    <template #body>
      <UForm :state="formState" :validate="validate" @submit="onSubmit">
        <UFormField label="Название">
          <InputDynamic
            v-model="formState.title"
            type="text"
            required
            autocomplete="off"
            size="xl"
            placeholder="Введите название папки"
          />
        </UFormField>

        <div class="pt-4 flex justify-end items-center gap-3">
          <UButton
            v-if="adminExplorerStore.getIsLoadingCreateFolder"
            loading
            label="Создание"
          />

          <template v-else>
            <UButton
              variant="ghost"
              color="neutral"
              label="Отмена"
              @click="onCloseCreatingFolder"
            />
            <UButton type="submit" label="Создать" color="success" />
          </template>
        </div>
      </UForm>
    </template>
  </UModal>
</template>

<style scoped></style>
